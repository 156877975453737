@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply bg-slate-50;
        @apply dark:bg-slate-900;

    }
    #root {
        @apply m-auto;
        @apply max-w-5xl;
        @apply text-slate-950;
        @apply dark:text-slate-50;
        @apply font-content;
        @apply flex;
        @apply flex-col;
        @apply h-screen;
        @apply p-6;
    }
}